import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import he from 'he'
import Fade from 'react-reveal/Fade'
import elasticlunr from 'elasticlunr'
//import he from 'he'
import 'url-search-params-polyfill'

import SEO from '../components/seo'
import { LoadMore } from '../components/icons'

class SearchPage extends Component {

  search = null

  constructor(props) {
    super(props)

    const urlParams = new URLSearchParams(this.props.location.search)

    this.state = {
      query: urlParams.get('search') || '',
      results: [],
      length: 12,
    }
  }

  componentDidMount() {
    this._register()

    if (this.state.query) {
      this.setState({
        results: this.search.search(this.state.query)
          .map(({ ref }) => this.search.documentStore.getDoc(ref)),
      })
    }

  }

  _register() {
    let index = elasticlunr()
    index.addField('title')
    index.addField('content')
    index.setRef('id')

     // TODO: this must be done on the API
    this.props.data.blog.edges.forEach((el) => {
      /*
      el.node.content = el.node.content_blocks_post.map((block) => {
        if (block.__typename === 'WordPressAcf_content') {
          return block.content.replace(/(<([^>]+)>)/ig, "")
        }
        return ''
      })
     */
      index.addDoc(el.node)
    })

     // TODO: this must be done on the API
    this.props.data.page.edges.forEach((el) => {
      /*
      el.node.content = el.node.acf.content_blocks_post.map((block) => {
        if (block.__typename === 'WordPressAcf_content') {
          return block.content.replace(/(<([^>]+)>)/ig, "")
        }
        return ''
      })
     */
      index.addDoc(el.node)
    })

    this.search = index
  }

  _renderResult = (el, i) => {
    if (el.node) el = el.node
    return (
      <div className='search-results__item' key={i}>
        <h4>
          <Link to={ el.type === 'post' ? `/article/${el.slug}` : `/${el.slug}` }>
            {he.decode(el.title)}
            <span>{ el.type === 'post' ? `emadvisory.com/article/${el.slug}` : `emadvisory.com/${el.slug}` }</span>
          </Link>
        </h4>
        <Link className='search-result__arrow' to={ el.type === 'post' ? `/article/${el.slug}` : `/${el.slug}` }>
          <LoadMore colour={'#000'} />
        </Link>
      </div>
    )
  }

  render() {
    let { results, length, query } = this.state

    if (results.length === 0) {
      setTimeout(() => this.refs.input && this.refs.input.focus(), 500)
    }

    return (
      <>
        <SEO title={'Search'} bodyClass="search" />
        <section className='search-results'>
          <div className='search-results__inner'>
            <Fade bottom distance={'40px'} delay={500}>
              <h1>Search result for: {query}</h1>
            </Fade>
            <Fade bottom distance={'40px'} delay={500}>
              <>
                { results.length === 0 &&
                  <div className='search-results__item'>
                    <h4>No results found.</h4>
                  </div>
                }
                { results.length !== 0 && results.slice(0, length).map(this._renderResult) }
              </>
            </Fade>
          </div>
        </section>
      </>
    )
  }
}

export default SearchPage

export const searchQuery = graphql`
  query {
    page: allWordpressPage {
      edges {
        node {
          id
          title
          slug
          type
          content
        }
      }
    }
    blog: allWordpressPost {
      edges {
        node {
          id
          title
          slug
          type
          content
        }
      }
    }
  }
`
